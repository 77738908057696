@font-face {
  font-family: "NeutraText";
  font-style: normal;
  src: local("NeutraText"), url(./fonts/NeutraText-Book.otf) format("opentype");
}

@font-face {
  font-family: "NeutraText";
  font-style: bold;
  font-weight: bold;
  src: local("NeutraText-Bold"),
    url(./fonts/NeutraText-Bold.otf) format("opentype");
}

html {
  height: 100%;
  width: 100%;
  overflow: hidden;
  /* font-family: NeutraText; */
}

body {
  margin: 0px;
  padding: 0px;
  height: 100%;
  overflow: auto;
}
